<template>
    <div class="page">
    
    </div>
  </template>
  
  <script>
  // import 'assets/css/style.css'
 
  import bgAnimateVue from "@/components/bg_animate.vue";
  
  export default {
    data() {
      return {
        headTitle: "岳阳南湖新区新时代文明",
      };
    },
    components: {
      bgAnimateVue,
    },
    computed: {},
    created() {
   
    },
    mounted() {},
    methods: {},
  };
  </script>
  <style scoped>
  .page {
    /* background: url(https://media.shouxinfund.com/yueyang/xinshidaiimg.png) no-repeat center; */
    background: url(https://home/ResImg/wang.png) no-repeat center;
    background-size: 100% 100%;
  }
  </style>